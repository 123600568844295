import React from 'react';
import ResponsiveEmbed from 'react-responsive-embed';
import { graphql } from 'gatsby';
import Layout from '../templates/layout';
import Newsfeed from '../components/newsfeed';
import Timeline from '../components/timeline';
import SEO from '../components/seo';

const IndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges.map(({ node }) => {
    return {
      id: node.id,
      title: node.frontmatter.title,
      path: node.frontmatter.path,
      image: node.frontmatter.cover.childImageSharp.sizes,
      date: node.frontmatter.date,
      excerpt: node.frontmatter.description || node.excerpt,
    };
  });
  return (
    <Layout>
      <SEO
        title="LHS Downtown"
        // image={cover.childImageSharp.fixed.src}
        description="The new Lowell High School project is currently moving through the Massachusetts School Building Association's process. This site is to highlight pieces of the project and keep Lowell residents up to date on the timeline and project updates"
      />

      <div className="container is-fluid content">
        <div className="columns is-6 is-variable is-widescreen">
          <div className="column" style={{ marginTop: '3rem' }}>
            <ResponsiveEmbed
              src="https://civplus.tikiliveapi.com/embed?scheme=embedVod&videoId=134779&autoplay=yes"
              allowFullScreen
            />
            <section className="hero">
              <div className="hero-body">
                <h1 className="title is-3">
                  Lowell students deserve the best.
                </h1>
                <p>
                  The new Lowell High School project is currently moving through
                  the Massachusetts School Building Association's process. We're
                  relaunching LHS Downtown as an informational site to keep you
                  up to date and highlight some of the best parts of the project
                  plans.
                </p>
              </div>
            </section>

            <section className="section">
              <h2 className="title is-4">Project Updates</h2>
              <hr />
              <Newsfeed posts={posts} />
            </section>
          </div>
          <div className="column">
            <div className="notification">
              <h2 className="title is-4" style={{ marginTop: '2rem' }}>
                Upcoming Project Milestones
              </h2>

              <Timeline />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allMarkdownRemark(
      sort: {
        fields: [frontmatter___date]
        order: DESC
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            date
            description
            cover {
              childImageSharp {
                sizes(maxWidth: 600) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
          excerpt
        }
      }
    }
  }
`;

export default IndexPage;
